.dbcards{
    min-width: 150px;
    min-height: 20px;
    max-height: 100px;
    border-radius: 10px;
    border: 1px solid #2780df;
    margin: 5px 5px;
}

.boxitem1{
    padding-top: 9px;
    align-items: center;
    font-size: 25px;
    height: 40px;
}
.boxitem2{
    padding: 0px;
    align-items: center;
    font-size: 25px;
}

.imagebox{
    background-color: #2287de;
    margin: 6px 2px;
    border-radius: 7px;
    max-height: 83px;
    max-width: 83px;
}
.text-center{
    text-align: center;
}
a{
    cursor: pointer !important;
}

.consumermodal{
    width: 70%;
    margin: 4em 15%;
}