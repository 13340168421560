.main-content {
    background-color: rgb(255, 255, 255);
    flex: 1;
    padding: 15px;
  }

  @media screen and (max-width: 1400px) {
    .main-content {
      padding-left: 10px;
      padding-right: 10px;
    }
    
  }
  

  @media screen and (max-width: 1200px) {
    .main-content {
      padding-right: 10px;
      padding-left: 10px;
    }
  }