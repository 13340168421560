/* width */
.tableresponsive::-webkit-scrollbar {
    width: 20px;
  }
  
  /* Track */
  .tableresponsive::-webkit-scrollbar-track {
    box-shadow: inset 1 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .tableresponsive::-webkit-scrollbar-thumb {
    background: rgb(103, 153, 233); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .tableresponsive::-webkit-scrollbar-thumb:hover {
    background: #1f47e8; 
  }

  .barChartStagewise{
    width: 100%;
    margin: 0%;
    margin-top: 2%;
  }