/* sidebar */
._navbar {
    background-color: var(--clr-primary);


    
  }
.nav-link-title{
    color: white !important;
  }
  
  ._nav-link {
    display: block;
    height: 44px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex;
    padding: 6px 14px;
    column-gap: 12px;
    letter-spacing: 0.03em;
    border: 1px solid transparent;
    
    
  }
  ._nav-link:hover {
    border: 1px solid var(--clr-primar-light);
    text-decoration: none;
  }
  ._nav-link.active {
    background-color: var(--clr-nav-active) !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }
  .nav-link-icon {
    width: 33px;
    height: 33px;
    margin: 0;
    filter: brightness(0) invert(1);
  }

  .nav-link-text {
    text-transform: capitalize;
    color: var(--clr-white);;
  }
  .shimmer{
    background-color: antiquewhite;
  }

  .nav-dropdown-link-icon{
    width: 25px;
    height: 25px;
    margin: 0;
  }
  .dropdown-item{
    align-items: left;
  }
