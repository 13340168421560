.cardBasicRed{
    height: 10em;
    width: 100%;
    background-color: rgb(230 97 106);
    border-radius: 10px;
    margin: 1em 0em;

    font-family: ui-serif;
    color: white;
    text-align: center;
    padding: 2em;
}

.cardBasicRed1{
    height: 10em;
    width: 98%;
    background-color: rgb(230 97 106);
    border-radius: 10px;
    margin: 1em 0em;

    font-family: ui-serif;
    color: white;
    text-align: center;
    padding: 2em;
}
.cardBasicBlue{
    height: 10em;
    width: 100%;
    background-color: #6030b3;
    border-radius: 10px;
    margin: 1em 0em;
    
    font-family: ui-serif;
    color: white;
    text-align: center;
    padding: 2em;
}
.row{
    width: 100%;
    height: 100%;
}
.cardBasic{
    height: 20em;
    width: 100%;
    background-color: rgb(173, 204, 227);
    border-radius: 10px;
    margin: 1em 0em;

}

.cardBasicBlue1{
    height: 10em;
    width:100%;
    background-color: #6030b3;
    border-radius: 10px;
    margin: 1em 0em;

    font-family: ui-serif;
    color: white;
    text-align: center;
    padding: 2em;

}
.textleft{
    text-align: left;
}
.textright{
    text-align: right;
}
.textlarge{
    font-size: 2.5em;
}
.cardBasicBlue2{
    height: 20em;
    width:98%;
    background-color: #6030b3;
    border-radius: 10px;
    margin: 1em 0em;

    font-family: ui-serif;
    color: white;
    text-align: center;
    padding: 2em;

}

*{
    font-size: 1em;
}

