._app {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 20px;
    flex-wrap: wrap;
    background-color: #ffffff;
  }
  
  .dataCard {
    background-color: #efefef;
    border-radius: 0.5em;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 1em;
  }
  
  .revenueCard {
    width: 92%;
    height: 20rem;
  }
  
  .customerCard {
    width: 50%;
    height: 20rem;
  }
  
  .categoryCard {
    width: 40%;
    height: 20rem;
  }
  label{
    font-weight: 600;
    margin-bottom: 5px;
  }